.ActionMenu span {
    position: absolute;
    width: 4px;
    height: 4px;
    background: #222;
    border-radius: 50%;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ActionMenu span:nth-child(1) {
    transform: translateY(-7px);
}

.ActionMenu span:nth-child(3) {
    transform: translateY(7px);
}

.ActionMenu.active span {
    width: 30px;
    height: 30px;
}

.ActionMenu.active span:nth-child(1) {
    transform: translateY(0) translateX(-35px);
}

.ActionMenu.active span:nth-child(3) {
    transform: translateY(0) translateX(35px);
}

.ActionMenu.active.edit span:nth-child(1) {
    transform: translateY(0) translateX(-18px);
}

.ActionMenu.active.edit span:nth-child(2) {
    transform: scale(0);
}

.ActionMenu.active.edit span:nth-child(3) {
    transform: translateY(0) translateX(18px);
}
